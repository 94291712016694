<template>
  <div class="card" :class="card.cardType" @click="viewCard">
    <img v-if="cardImg" :src="cardImg" class="card-image" alt="Card">
    <div v-else class="card__default" />
    <div class="card__info">
      <div>
        <p class="card-type">
          {{
            $t(
              card.cardType === 'physical'
                ? 'cardType_physical'
                : 'cardType_virtual'
            )
          }}
        </p>
        <p class="card-limit-use">
          {{ spendingLimit }}
        </p>
      </div>
      <div>
        <div>
          <p class="card-number" id="secret-data" ref="secret-data" v-if="card.cardType === 'virtual' && showSecretData" />
          <p class="card-number" v-else>
            **** **** **** {{ card.last4 }}
          </p>
          <div class="exp-cvv">
            <div>
              <p>EXP</p>
              <span>{{ card.expiryMonth }} / {{ card.expiryYear }}</span>
            </div>
            <div v-if="card.cardType === 'virtual' && showSecretData">
              <p>{{ $t('cardInfo_detail_cvv') }}</p>
              <span id="secret-data-cvv" ref="secret-data-cvv" />
            </div>
          </div>
          <p class="card-holder">
            {{ card.label }}
          </p>
          <p class="card-holder-company" v-if="getSelectedBusiness">
            {{ getSelectedBusiness.legalName }}
          </p>
          <div v-if="showActivationSwitch" class="active-wrapper">
            <el-switch
              @click.native="toggleFreeze($event)"
              :width="35"
              class="keep-default"
              v-model="isActive" />
            <span>{{ cardStatus[card.cardStatus] }}</span>
          </div>
        </div>
        <div class="card-logo" v-if="showLogo">
          <img src="@/assets/images/logos/visa-logo.svg" alt="Visa">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import { cardLimit, cardStatus, cardType } from '../enums/index';
import { getEnv } from '@/utils/env';
import { currencyFormatter } from '@/utils/general';

export default {
  name: 'Card',
  props: {
    // eslint-disable-next-line
    card: Object,
    showActivationSwitch: {
      type: Boolean,
      default: false
    },
    showLogo: {
      type: Boolean,
      default: true
    },
    showSecretData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: this.card.cardStatus === 'active',
      cardStatus,
      cardType,
    };
  },
  computed: {
    ...mapGetters('user', ['getPersonDetails', 'getProgramDetails', 'getUserAuthToken']),
    ...mapGetters('kyb', ['getSelectedBusiness']),
    spendingLimit() {
      return currencyFormatter(this.card.limitAmount) + ' ' + (cardLimit['cardBoxTitle'][this.card.limitInterval] || '');
    },
    cardImg() {
      const programDetails = this.getProgramDetails;
      if (this.card.cardType == 'physical') {
        return programDetails &&
          programDetails.brand &&
          programDetails.brand.cardArtPhysical
          ? programDetails.brand.cardArtPhysical
          : null;
      } else {
        return programDetails &&
          programDetails.brand &&
          programDetails.brand.cardArtVirtual
          ? programDetails.brand.cardArtVirtual
          : null;
      }
    }
  },
  mounted() {
    if(this.showSecretData && this.card.cardType === 'virtual') {
      window.addVgsScript(() => {
        this.getCardSecret();
      });
    }
  },
  methods: {
    ...mapMutations('card', ['updateSelectedCard']),
    ...mapActions('card', ['updateCard', 'showToken']),
    activate(e) {
      e.stopPropagation();
      this.updateSelectedCard(this.card);
      setTimeout(() => {
        this.$emit('changeDrawer', 'activate')
      }, 0);
    },
    viewCard(e) {
      window.analytics && window.analytics.track('homeCardDetails');
      if (this.card.cardStatus === 'pendingActivation') {
        this.activate(e);
        return;
      }
      this.updateSelectedCard(this.card);
      this.$emit('changeDrawer', 'manage');
    },
    toggleFreeze(e) {
      e.stopPropagation();
      if (this.card.cardStatus === 'pendingActivation') {
        this.isActive = false;
        this.activate(e);
        return;
      }
      const freezeCard = this.card.cardStatus === 'active';
      this.$confirm(
        this.$t(
          freezeCard
            ? 'cardInfo_freeze_alert_messsage'
            : 'cardInfo_unfreeze_alert_messsage'
        ),
        this.$t(
          freezeCard
            ? 'cardInfo_freeze_alert_title'
            : 'cardInfo_unfreeze_alert_title'
        ),
        {
          confirmButtonText: this.$t(
            freezeCard ? 'cardInfo_freeze_button' : 'cardInfo_unfreeze_button'
          ),
          cancelButtonText: this.$t('cancel')
        }
      )
        .then(() => {
          this.freeze();
        })
        .catch(() => {
          this.isActive = !this.isActive;
        });
    },
    freeze() {
      const loader = this.showLoader();
      this.updateCard({
        cardId: this.card.id,
        payload: {
          cardStatus: !this.isActive ? 'inactive' : 'active'
        }
      })
        .then((res) => {
          this.isActive = res.cardStatus === 'active';
          this.card.cardStatus = res.cardStatus;
          this.$emit('update', this.card);
        })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    },
    /**
     * get secret data
     */
    getCardSecret() {
      
      let vaultId = getEnv('VUE_APP_VGS_VALUTID');
      const loader = this.showLoader();
      this.showToken(this.card.id).then((res) => {
        const headers = {'sd-show-token' : res.showToken}

        let show = window.VGSShow.create(vaultId);
        /**
         * card number
         */
        const cardNoiframe = show.request({
          name: 'cardNoIframe',
          method: 'GET',
          headers,
          path: `/v1/card/${this.card.id}/show`,
          jsonPathSelector: 'cardNumber',
          serializers: [show.SERIALIZERS.replace('(\\d{4})(\\d{4})(\\d{4})(\\d{4})', '$1 $2 $3 $4')],
        });
        cardNoiframe.render(this.$refs['secret-data'], { color: '#fff', fontSize: '14px', display: 'block', height: '16px', overflow: 'hidden'});

        /**
         * cvv
         */
        const iframe = show.request({
          name: 'cvvIframe',
          method: 'GET',
          headers,
          path: `/v1/card/${this.card.id}/show`,
          jsonPathSelector: 'cvv'
        });
        iframe.render(this.$refs['secret-data-cvv'], { color: '#fff', fontSize: '16px', display: 'block', height: '16px', overflow: 'hidden'});
      
        this.$emit('cardNumberDisplay', {show, headers});
      }).catch(() => {

      }).finally(() => {
        loader.close();
      });
      
    }
  },
  watch: {
    card: {
      handler(val) {
        this.isActive = val.cardStatus === 'active';
      },
      deep: true
    }
  }
};
</script>
<style lang="scss">
.card {
  cursor: pointer;
  width: 220px;
  position: relative;
  transition: 0.2s;
  margin-right: 20px;

  &.virtual {
    // border: 1px solid #E8EBEE;
    border-radius: var(--radius);
    .exp-cvv,
    .card-number,
    .per-day,
    .card-holder,
    .card-holder-company,
    .card-limit-label {
      color: #fff;
    }
  }

  &__default {
    height: 211px;
  }

  .card-image {
    width: 100%;
    height: 100%;
  }

  .logo {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
  }

  &:hover {
    .card--activate {
      opacity: 1;
    }
  }

  .exp-cvv {
    display: flex;
    padding-top: 7px;
    
    div {
      margin-right: 53px;
      p {
        color: rgba(#fff, .6);
        font-size: 11px;
        padding-bottom: 3px;
      }
      span {
        font-size: 14px;
        font-weight: 700;
        color: #fff;
      }
    }
  }

  &__info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 16px 32px 16px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .card-holder-company,
  .card-holder {
    color: #fff;
    font-weight: 700;
    font-size: 10px;
    padding-top: 24px;
    padding-bottom: 4px;
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .card-holder-company {
    padding: 4px 0 0 0;
  }

  .card-type {
    color: #fff;
    font-weight: 700;
    font-size: 11px;
    padding: 12px 0 2px 0;
    line-height: 16px;
  }

  .card-limit-use {
    color: #fff;
    font-size: 9px;
    font-weight: 700;
  }

  .per-day {
    color: #fff;
    font-size: 19px;
    line-height: 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  .card-limit-label {
    color: white;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding-bottom: 24px;
  }
  .active-wrapper {
    position: absolute;
    bottom: 94px;
    right: 16px;

    .el-switch {
      margin-right: 8px;
    }
  
    span {
      color: #a1a1a1;
      font-size: 12px;
    }
  }

  .card-number {
    color: #fff;
    font-size: 12px;
  }

  .card-logo {
    position: absolute;
    right: 16px;
    bottom: 32px;
  }

  &--activate {
    background-color: rgba(#ffffff, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--radius);
    opacity: 0;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
      opacity: 1;
    }
  }
}
</style>
<style lang="scss">
.card {
  iframe {
    height: 16px;
    width: 100%;
  }
  .exp-cvv {
    div {
      min-width: 70px;
    }
  }
}
</style>
